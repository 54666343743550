import * as React from 'react';
import { Layout } from '../components/layout';

const AboutPage = () => {
  return (
    <Layout pageTitle="About Me">
      <p>Hi there!</p>
    </Layout>
  )
}

export default AboutPage
